import React, { createContext, useReducer } from 'react';
import { useCart } from 'react-use-cart';
import { useCookies } from 'react-cookie';

import { supportedCurrencies } from './../../data/languages'
import { canUseDOM } from './../utils/cart-helpers'

const CheckoutContext = createContext();

function reducer(state, { payload, type }) {
  switch (type) {
    case 'CHECKOUT_PROCESSING':
      return {
        ...state,
        processing: true,
        error: null,
      };
    case 'CHECKOUT_ERROR':
      return {
        ...state,
        processing: false,
        allowPayment: false,
        shipping: '-',
        gifts: [],
        error: payload.message
      };
    case 'CHECKOUT_SUCCESS':
      return {
        ...state,
        allowPayment: false,
        processing: false,
        shipping: '-',
        shippingOptions: [],
        shippingDiscount: 0,
        isFreeShipping: false,
        gifts: [],
        error: null,
        success: true,
      };
    case 'CHECKOUT_UPDATE_SHIPPING_OPTIONS':
      return {
        ...state,
        shippingOptions: payload.shippingOptions || [],
      }
    case 'CHECKOUT_UPDATE_SHIPPING':
      return {
        ...state,
        shipping: payload.shippingCost || '-',
        shippingDiscount: payload.shippingDiscount || 0,
        isFreeShipping: payload.isFreeShipping || false
      };
    case 'CHECKOUT_UPDATE_MIDTRANS_TOKEN':
      return {
        ...state,
        midtransToken: payload.token,
        midtransSaleId: payload.saleId,
      };
    case 'CHECKOUT_UPDATE_TAX':
      return {
        ...state,
        tax: payload.tax,
        isTaxIncluded: payload.isTaxIncluded
      };
    case 'SET_GIFTS':
      return {
        ...state,
        gifts: payload,
      };
    case 'SET_GIFT_NOT_WANTED':
      return {
        ...state,
        giftNotWanted: payload,
      };
    case 'CHECKOUT_PAYMENT':
      return {
        ...state,
        formValues: payload,
        allowPayment: true,
        processing: false,
        error: null,
      };
    case 'DISABLE_PAYMENT':
      return {
        ...state,
        allowPayment: false,
        success: false,
        processing: false,
        error: null,
        shipping: '-',
        tax: 0,
        discountType: null,
        discount: 0,
      };
    case 'CHECKOUT_UPDATE_CURRENCY':
      return {
        ...state,
        customCurrency: payload.code,
        customLocale: payload.locale,
        customCountryCode: payload.countryCode,
        latitude: payload.latitude || -8,
        longitude: payload.longitude || 115,
        isCurrencySet: true,
        formValues: payload.formValues
      };
    case 'CHECKOUT_SET_INVALID_ITEM':
      return {
        ...state,
        invalidItems: payload
      };
    default:
      throw new Error('Invalid action');
  }
}

function CheckoutProvider({ children }) {
  const { cartTotal, items } = useCart();
  const [cookies, setCookie] = useCookies(['sensatiaCurrency']);
  const { sensatiaCurrency = '' } = cookies
  const [state, dispatch] = useReducer(reducer, {
    allowPayment: false,
    processing: false,
    error: null,
    success: false,
    shipping: '-',
    tax: 0,
    discountType: null,
    discount: 0,
    midtransToken: '',
    midtransSaleId: '',
    customCurrency: sensatiaCurrency || 'USD',
    customLocale: 'en',
    customCountryCode: 'US',
    isCurrencySet: sensatiaCurrency !== '' || !canUseDOM,
    formValues: {},
    isTaxIncluded: false,
    isFreeShippping: false,
    shippingDiscount: 0,
    gifts: [],
    giftNotWanted: false,
    invalidItems: [],
  });

  const disablePayment = () => {
    // require to re-calculate shipping-costs
    dispatch({ type: 'DISABLE_PAYMENT' });
  }

  const checkoutError = payload => {
    dispatch({ type: 'CHECKOUT_ERROR', payload });
  };

  const setGifts = payload => {
    dispatch({ type: 'SET_GIFTS', payload });
  };

  const setGiftNotWanted = payload => {
    dispatch({ type: 'SET_GIFT_NOT_WANTED', payload });
  };

  const checkoutPayment = (payload) => {
    dispatch({ type: 'CHECKOUT_PAYMENT', payload });
  };

  const checkoutProcessing = () => {
    dispatch({ type: 'CHECKOUT_PROCESSING' });
  };

  const checkoutSuccess = () => {
    dispatch({ type: 'CHECKOUT_SUCCESS' });
  };

  const orderTotal = cartTotal + (state.isTaxIncluded || isNaN(state.tax) ? 0 : state.tax) + (!isNaN(state.shipping) ? state.shipping : 0);

  const updateShipping = payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_SHIPPING', payload });
  };

  const updateShippingOptions = payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_SHIPPING_OPTIONS', payload });
  };
  

  const updateTax = payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_TAX', payload });
  };

  const updateMidtransToken = payload => {
    dispatch({ type: 'CHECKOUT_UPDATE_MIDTRANS_TOKEN', payload });
  };

  const setInvalidItems = payload => {
    dispatch({ type: 'CHECKOUT_SET_INVALID_ITEM', payload });
  };

  // update currency for receiver-country handled by other site-warehouse
  const updateCustomCurrency = ({ currency: code = 'USD', locale = 'en', formValues = {} }) => {
    dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { code, locale, formValues } });
  }

  const updateCurrency = async payload => {
    if (canUseDOM) {
      const res = await fetch('/.netlify/functions/currency-lookup')
      if (res.ok) {
        const result = await res.json()
        let payload
        const isValidLookup = result && result.locale && result.code && supportedCurrencies.includes(result.code)
        if (isValidLookup) {
          payload = result
        } else {
          const { latitude, longittude, countryCode, continentCode, code } = result
          const isEuropean = continentCode === 'EU'
          const isRussian = code === 'RUB'
          const customCurrency = isEuropean || isRussian ? 'EUR' : "USD"
          payload = { code: customCurrency, locale: 'en', countryCode, latitude, longittude }
        }
        const { code } = payload
        // empty cart if currency mismatch
        const itemForCurrency = items.find(itm => itm.currency !== code)
        const isCurrencyMissmatch = itemForCurrency && itemForCurrency.id
        if (isCurrencyMissmatch) {
          console.log('currency-missmatch, emptycard.')
          // emptyCart()
        }
        setCookie('sensatiaCurrency', code, { path: '/' });
        dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload });
      } else {
        console.log('unable to perform currency-lookup. emptycard')
      }
    } else {
      setCookie('sensatiaCurrency', 'USD', { path: '/' });
      dispatch({ type: 'CHECKOUT_UPDATE_CURRENCY', payload: { code: 'USD', locale: 'en', countryCode: 'US' } });
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        checkoutError,
        checkoutPayment,
        checkoutProcessing,
        checkoutSuccess,
        orderTotal,
        updateShipping,
        updateShippingOptions,
        updateTax,
        updateMidtransToken,
        updateCurrency,
        updateCustomCurrency,
        disablePayment,
        setGifts,
        setGiftNotWanted,
        setInvalidItems,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}

export { CheckoutProvider, CheckoutContext as default };
