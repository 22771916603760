export const canUseDOM = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

export const getItemsOrder = ({items = ''}) => {
  const itemsOrder = items.map(item => {
    const { quantity: qty = 0, price, id: _id, voucherId = '', name = '', isGift = false, isUseOrigPrice = false, disc = 0, unitPrice = 0 } = item
    const extPrice = qty * price
      // max length for name    
    let nameClean = name
    if (nameClean.length > 50) {
      nameClean = name.substring(0, 50)
    }
    const itemClean = {
      name: nameClean,
      extPrice,
      price,
      _id,
      unitPrice,
      qty,
      isUseOrigPrice,
      disc
    }
    if (voucherId !== '') {
      itemClean['voucherId'] = voucherId
    }
    if (isGift === true) {
      itemClean['isGift'] = true
    }
    return itemClean
  })
  return itemsOrder
}